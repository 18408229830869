.form-section {
	.frame {
		float: left;
		width: 100%;
		background-color: #fff;
		.btn-purple {
			display: block;
			position: absolute;
			right: 50px;
			top: -100px;
			width: 150px;
			height: 150px;
			border-radius: 150px;
			background-color: $purple; 
			padding-top: 38px;
			padding-left: 0px;
			font-family: 'Oranienbaum', serif;
			font-weight: 600;
			color: #fff;
			line-height: 20px;
			text-align: center;
  			font-size: 20px;
  			line-height: 25px;
  			z-index: 999;
		}
		.inner {
			width: 80%;
			margin: 0 auto;
			.form-cell {
				float: left;
				width: 100%;
				background-color: rgb(232, 241, 239);
				padding: 80px;
			}
		}
		form {
			position: relative;
			float: left;
			width: 80%;
			// background-color: green
			li {
				float: left;
				width: 100%;
				position: relative;
				padding: 2px 0;
				margin-top: 0px !important;
				.gfield_label {	
					float: left;
					width: 30%;
  					font-size: 16px;
  					font-weight: 600;
  					padding-top: 15px;
  					color: rgb(74, 93, 97);
				}
				input {
					width: 70% !important;
					float: left;
					padding-left: 15px !important;
					height: 40px;
					border: none;
					padding-left: 10px;
					border: 1px solid #c8d5d2;
				}
				textarea {
					width: 70%;
					float: left;
					padding: 15px;
					height: 100px;
					border: none;
					border: 1px solid #c8d5d2;
					margin-bottom: 20px;
				}
				.ginput_container_select  {
					color: red;  
					height: 40px;
					width: 70%;
					float: right;
					margin-bottom: 40px;
					border-radius: 2px;
					background-color: #fff;
					border: 1px solid #c8d5d2;
					p {
						float: left;
						width: 100%;
  						font-size: 16px;
  						font-weight: 600;
  						padding: 10px;
  						color: rgb(74, 93, 97);
					}
			    }
			    .ginput_container_select select {

			        width: 100%;
			        height: 42px;
			        font-size: 1em;
			        padding-top: 0;
			        line-height: 15px;
			        padding-left: 15px;
			        @include appearance(none);
			        background: rgba(0,0,0,0);
			        border: none;
			        cursor: pointer;
			        display: block;
			        margin: 0;
			        outline: none;
			    }
			}
			.gform_footer {
				float: left;
				width: 100%;
				position: relative;
				padding: 2px 0;

				h6 {
					display: inline;
					float: left;
					margin-top: 10px;
					margin-left: 30%;
  					font-size: 16px;
  					font-style: italic;
  					color: rgb(74, 93, 97);
				}
				input {
					float: right;
					border: none;
					padding: 10px 30px;
					background-color: $purple;
					margin-top: 0px;
				  	font-size: 16px;
				  	color: rgb(255, 255, 255);
				}
			}
		}
	}
}
.single-news {
	form {
		position: relative;
		float: left;
		width: 100%;
		background-color: rgb(232, 241, 239);
		margin: 60px 0;
		padding-bottom: 40px;
		.gform_title { 
			text-align: center !important;
			font-size: 20px;
    		color: #4a5d61;
		}
		li {
			float: left;
			width: 100%;
			position: relative;
			padding: 2px 0;
			margin-top: 0px !important;
			.gfield_label {	
				float: left;
				width: 30%;
					font-size: 16px;
					font-weight: 600;
					padding-top: 15px;
					color: rgb(74, 93, 97);
			}
			input {
				width: 70% !important;
				float: left;
				padding-left: 15px !important;
				height: 40px;
				border: none;
				padding-left: 10px;
				border: 1px solid #c8d5d2;
			}
			textarea {
				width: 70% !important;
				float: right;
				padding: 15px;
				height: 100px;
				border: none;
				border: 1px solid #c8d5d2;
				margin-bottom: 20px;
			}
			.ginput_container_select  {
				color: red;  
				height: 40px;
				width: 70%;
				float: right;
				margin-bottom: 40px;
				border-radius: 2px;
				background-color: #fff;
				border: 1px solid #c8d5d2;
				p {
					float: left;
					width: 100%;
						font-size: 16px;
						font-weight: 600;
						padding: 10px;
						color: rgb(74, 93, 97);
				}
		    }
		    .ginput_container_select select {

		        width: 100%;
		        height: 42px;
		        font-size: 1em;
		        padding-top: 0;
		        line-height: 15px;
		        padding-left: 15px;
		        @include appearance(none);
		        background: rgba(0,0,0,0);
		        border: none;
		        cursor: pointer;
		        display: block;
		        margin: 0;
		        outline: none;
		    }
		}
		.gform_footer {
			float: left;
			width: 100%;
			position: relative;
			padding: 2px 0;

			h6 {
				display: inline;
				float: left;
				margin-top: 10px;
				margin-left: 30%;
					font-size: 16px;
					font-style: italic;
					color: rgb(74, 93, 97);
			}
			input {
				float: right;
				border: none;
				padding: 10px 30px;
				background-color: $purple;
				margin-top: 0px;
			  	font-size: 16px;
			  	color: rgb(255, 255, 255);
			}
		}
	}
}


.banner {
	.frame {
		float: left;
		width: 100%;
		border-bottom: 1px solid #8ca1a5;
		padding: 60px 0;
		background-color: #fff;
	}
	img {
		position: relative;
		left: 50%;
		@include transform(translate(-50%,0));
		width: 100%;
		max-width: 550px;
		background-size: cover;
		background-position: center center;
	}
	p {
  		font-size: 16px;
  		
  		color: rgb(51, 51, 51);
  		text-align: center;
		line-height: 20px;
	}
}



@media screen and (max-width: 1100px) {
  .form-section {
  	.frame {
  		.inner {
  			width: 95%;
  			.form-cell {
  				form {

  				}
  			}
  		}
  	}
  }
}

@media screen and (max-width: 959px) {
  .form-section {
  	.frame {
  		.inner {
  			width: 95%;
  			.form-cell, {
  				form {
  					width: 100%;

  				}
  			}
  		}
  	}
  }
  .single-news {
  	form {
		width: 100%;

	}
  }
}

@media screen and (max-width: 767px) {
  .form-section {
  	.frame {
  		.inner {
  			width: 95%;
  			.form-cell {
  				padding: 80px 10px;
  				form {
  					width: 100%;

  				}
  			}
  		}
  	}
  }
}

@media screen and (max-width: 479px) {
  .form-section {
  	.frame {
  		.inner {
  			width: 95%;
  			.form-cell {
  				padding: 80px 10px;
  				form {
  					width: 100%;
  					.gfield_label {
  						padding-bottom: 2px;
  					}
					input,
					textarea,
					.ginput_container_select {
						width: 100% !important;
					}
  				}
  			}
  		}
  	}
  }

  .single-news {
  	form {
		.gfield_label {
			padding-bottom: 2px;
		}
		li {
			input,
			textarea,
			.ginput_container_select {
				width: 100% !important;
			}
		}
	}
  }

  .form-section .frame .btn-purple {

    top: -50px;

	}
}






