/* ==========================================================================
   http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
   ========================================================================== */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

html, 
body {
  height: 100%;
}


html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}



body {
  // background-color: #FAFAFA;
  color: #000;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}

a {
    /*color: #444;*/
    text-decoration: none;
  line-height: inherit;
  word-wrap: break-word;
}

.no-touch a:hover,
.no-touch a:active { 
  color: #999;
  cursor: pointer; 
}

a img { border: none; }
    
img {
  height: auto;
  vertical-align: middle;
}

img { -ms-interpolation-mode: bicubic; }
  
i, 
em {
    font-style: italic;
    line-height: inherit;
}

b, 
strong,
.bold { 
    font-weight: 700;
    line-height: inherit;
}

/* Standard heading styles */
h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
  margin-bottom: 12px;
  font-weight: 700;
  line-height: 1.5em;
  text-rendering: optimizeLegibility;
}

h1 small, 
h2 small, 
h3 small, 
h4 small, 
h5 small, 
h6 small {
  font-size: 60%;
  line-height: 0; 
}

h1 { font-size: 36px; }
h2 { font-size: 24px; }
h3 { font-size: 16px; }
h4 { font-size: 16px; }
h5 { font-size: 16px; }
h6 { font-size: 16px; }

/* Standard paragraph styles */
p {
  font-family: inherit;
  font-weight: normal;
  text-rendering: optimizeLegibility; 
}

p:last-child {
  margin-bottom: 0;
}

small, 
sub, 
sup { font-size: .63em; }
sub { vertical-align: sub; }
sup { vertical-align: super; }

/* Standard form styles */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="password"],
input[type="number"],
input[type="search"],
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"],
input[type="submit"],
textarea,
button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-radius: 0;
  outline: none;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="submit"],
button {
  cursor: pointer;
}

textarea {
  overflow: auto; /* Remove default vertical scrollbar in IE 8/9 */
  vertical-align: top; /* Improve readability and alignment in all browsers */
}

/* blockquote */
blockquote {
  padding-left: 50px;
  margin-bottom: 1.25em;
}

/* Placeholder texts */
::-webkit-input-placeholder { color: #999; }

:-moz-placeholder {
  color: #000;
  opacity: 1; /* Removes placeholder transparency in Firefox */
}

::-moz-placeholder {
  color: #000;
  opacity: 1; /* Removes placeholder transparency in Firefox */
}

:-ms-input-placeholder { color: #999; }

/* Clear floats by wrapping this around the parent element */
.group:before,
.group:after {
  content: "";
  display: table;
} 
.group:after {
  clear: both;
}


$gray : #Fafafa;
/* Standard section styles */
section, 
.section {
  position: relative;
    z-index: 0;
    float: left;
    width: 100%;
    background-color: $gray;
    
}

/* Standard container */
.container {
    position: relative;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
}


/* Selection */
::-moz-selection { /* Firefox */
    background: #000;
    color: #FFF;
    text-shadow: none;
}
::selection {
    background: #000;
    color: #FFF;
    text-shadow: none;
}

/* ==========================================================================
   STRUCTURE
   ========================================================================== */

.row {
  margin-left: -10px;
  margin-right: -10px;  
}

.row:before,
.row:after {
  display: table;
  content: " ";
}

.row:after {
  clear: both;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-11,
.col-10,
.col-12 {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.col-1 { width: 8.3333333333%; }
.col-2 { width: 16.66666666667%; }
.col-3 { width: 25%; }
.col-4 { width: 33.3333333333% }
.col-5 { width: 41.6666666667% }
.col-6 { width: 50%; }
.col-7 { width: 58.3333333333%; }
.col-8 { width: 66.6666666667%; }
.col-9 { width: 75%; }
.col-12 { width: 100%; }

.col-offset-1 { margin-left: 8.3333333333%; }
.col-offset-2 { margin-left: 16.66666666667%; }
.col-offset-3 { margin-left: 25%; }
.col-offset-4 { margin-left: 33.3333333333% }
.col-offset-6 { margin-left: 50%; }
.col-offset-7 { margin-left: 58.3333333333%; }
.col-offset-8 { margin-left: 66.6666666667%; }
.col-offset-9 { margin-left: 75%; }
.col-offset-12 { margin-left: 100%; }


.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}







/* ==========================================================================
   Author's custom styles
   ========================================================================== */

$black: #333333;
$purple: #79567b;
$green: #819d98;
$darkgreen: #4a5d61;


body {
  font-family: 'Avenir', sans-serif;
  font-weight: 400;
  font-style: normal;
}















/* ==========================================================================
   media styles
   ========================================================================== */


@media screen and (max-width: 959px) {

}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 479px) {
  
}





